<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <!-- <transition name="fade" mode="in-out">
    <router-view />
  </transition> -->

  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
// console.log("coucou")
// import router from "./router"

export default {
  name: "IndexPage",
  props: {},
  mounted() {
    console.log("mounted")
    // router.push("/")
  },
}
</script>

<style>
@font-face {
  font-family: "PPFraktionMono-Regular";
  src: url("~@/assets/fonts/PPFraktionMono-Regular.woff2");
}
@font-face {
  font-family: "PPFraktionSans-Variable";
  src: url("~@/assets/fonts/PPFraktionSans-Variable.woff2");
}
@font-face {
  font-family: "PPMonumentExtended-Regular";
  src: url("~@/assets/fonts/PPMonumentExtended-Regular.woff2");
}

#app {
  font-family: "PPFraktionSans-Variable", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}

body {
  margin: 0;
  padding: 0;
  background: #000;
}

* {
  box-sizing: border-box;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s linear;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
